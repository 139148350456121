<template>
	<div class="container">
		<back pre />
		<h2 v-if="project">{{project.projectName}} - <span style="font-size: 30px;">{{project.head}}</span></h2>
		<div class="work-list-box">
			<div class="menu">
				<div class="button-box">
					<el-button  @click="nextStage" v-if="project&&stageList.length>0&&project.completion == 0"
						type="danger" style="margin-right: 20px;">
						结束{{getStage(project.stage)}}</el-button>
					<i v-if="project&&project.completion == 0" class="el-icon-circle-plus-outline" @click="addWorkWindow()"></i>
				</div>
			</div>
			<div class="work-list">
				<div style="width: 100%;height: 100%;overflow: auto;">
					<div v-if="project&&item.stage<=project.stage" v-for="(item,i) in workList" :key="i">
						<el-divider>{{item.stageTitle}}阶段</el-divider>
						<div class="work-item">
							<el-empty v-if="item.list.length==0" description="暂无数据"></el-empty>
							<el-collapse v-else accordion @change="collapseChange">
								<el-collapse-item v-for="(work,j) in item.list" :key="j" :name="j">
									<template slot="title">
										<div class="work-title"
											:style="{'color':work.check&&work.stage==project.stage&&project.completion==0?'#ccc':'#000000','textDecoration':work.check&&work.stage==project.stage&&project.completion==0?'line-through':'none'}">
											<el-checkbox style="margin: 0 10px;"
												v-if="item.stage == project.stage&&project.completion==0"
												v-model="work.check" @change="workCheck(work)">
											</el-checkbox>
											<span @click.stop="editWorkWindow(work)">{{work.workContent}}</span>
											<span v-if="work.workExecList.length>0"
												style="font-size: 16px;color: #b0b0b0;line-height: 50px;padding-left: 30px;">{{work.workExecList[0].reason}}</span>
											<transition name="el-fade-in-linear">
												<div class="work-button"
													v-if="j===collapseCurrent&&work.stage==project.stage&&project.completion==0">
													<i class="el-icon-circle-plus-outline"
														@click.stop="addExecWindow(work)"></i>
												</div>
											</transition>
										</div>
									</template>
									<el-timeline>
										<el-timeline-item v-for="(exec,y) in work.workExecList" :key="y"
											:timestamp="exec.startTime+' 至 '+exec.endTime" placement="top">
											<el-card style="width: 40%;position: relative;">
												<div style="cursor: pointer;" @click="editExecWindow(exec,work)">
													<div v-if="exec.type==1" style="background-color: #C83434;"
														class="exec-type">
													</div>
													<div v-if="exec.type==2" style="background-color: #FC9E30;"
														class="exec-type">
													</div>
													<div v-if="exec.type==3" style="background-color: #34A4C8;"
														class="exec-type">
													</div>
													<div v-if="exec.type==4" style="background-color: #8ABF33;"
														class="exec-type">
													</div>
													<h4 style="font-size: 16px;">原因：{{exec.reason}}</h4>
													<p>解决方案：{{exec.solve?exec.solve:'无'}}</p>
												</div>
											</el-card>
										</el-timeline-item>
									</el-timeline>
								</el-collapse-item>
							</el-collapse>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :close-on-click-modal="false" title="设置工作" :destroy-on-close="true" :visible.sync="workWindow" width="40%"
			@close="clo('workForm')">
			<el-form :model="workForm" :rules="workRules" ref="workForm" label-width="150px" class="workForm">
				<el-form-item label="工作内容" prop="workContent">
					<el-input type="textarea" :rows="4" v-model="workForm.workContent"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="workWindow = false">取 消</el-button>
				<el-button type="primary" @click="saveWork('workForm')">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" title="工作记录" :destroy-on-close="true" :visible.sync="execWindow" width="40%"
			@close="clo('execForm')">
			<el-form :model="execForm" :rules="execRules" ref="execForm" label-width="130px" class="workForm">
				<el-form-item label="原因分析" prop="reason">
					<el-input type="textarea" :rows="2" v-model="execForm.reason"></el-input>
				</el-form-item>
				<el-form-item label="解决方案">
					<el-input type="textarea" :rows="2" v-model="execForm.solve"></el-input>
				</el-form-item>
				<el-form-item label="需解决程度" prop="type">
					<el-select v-model="execForm.type" placeholder="请选择项目状态">
						<el-option label="重要且紧急" :value="1"></el-option>
						<el-option label="重要不紧急" :value="2"></el-option>
						<el-option label="紧急不重要" :value="3"></el-option>
						<el-option label="不重要不紧急" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="计划时间">
					<el-date-picker v-model="execForm.time" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="execWindow = false">取 消</el-button>
				<el-button type="primary" @click="saveExec('execForm')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getProjectDetail,
		getDict,
		getProjectWorkList,
		saveProjectWork,
		saveWorkExec,
		changeProjectStage
	} from "@/api";
	import back from "@/components/back";
	export default {
		name: "projectWork",
		components: {
			back,
		},
		data() {
			return {
				project: null, //项目信息
				stageList: [], //阶段列表
				isEnd: false, //项目已完结
				workForm: {},
				workWindow: false,
				workList: [],
				execWindow: false,
				execForm: {},
				collapseCurrent: null, //当前展开的面板
				workRules: {
					workContent: [{
						required: true,
						message: '请输入工作内容',
						trigger: 'blur'
					}, ]
				},
				execRules: {
					reason: [{
						required: true,
						message: '请输入原因',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择项目状态',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			//项目进入下一个阶段
			nextStage() {
				this.$confirm('确认进入下一阶段，此操作不可回退', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					changeProjectStage({
						projectId: this.$route.params.projectId
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('成功')
							this.init()
						}
					})
				})

			},
			//根据字典值获取键
			getStage(stage) {
				return this.stageList.filter(item => item.dictValue == stage)[0].dictLabel
			},
			//面板切换
			collapseChange(val) {
				this.collapseCurrent = val
			},
			//保存工作执行记录
			saveExec() {
				if (!this.execForm.time || this.execForm.time.length == 0) {
					this.$message.error("请选择时间")
					return
				}
				this.execForm.startTime = this.execForm.time[0]
				this.execForm.endTime = this.execForm.time[1]
				saveWorkExec(this.execForm).then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.$message.success('成功！')
						this.getProjectWorkData()
						this.execWindow = false
					}
				})
			},
			editExecWindow(item, work) {
				if (this.project.completion!=0|| work.stage != this.project.stage) return
				item.time = [item.startTime, item.endTime]
				this.execForm = JSON.parse(JSON.stringify(item))
				this.execWindow = true
			},
			addExecWindow(work) {
				this.execForm = {
					time: '',
					workId: work.workId
				}
				this.execWindow = true
			},
			workCheck(item) {
				// console.log(item);
				item.completion = item.check ? 1 : 0
				saveProjectWork(item).then(res => {
					if (res.code == 200) {
						this.$message.success('修改成功')
						this.getProjectWorkData()
					}
				})
			},
			reset() {
				// this.
			},
			clo(formName) {
				this.$refs[formName].resetFields();
			},
			//保存项目工作
			saveWork(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// console.log(111);
						this.workForm.projectId = this.$route.params.projectId
						saveProjectWork(this.workForm).then(res => {
							// console.log(res);
							if (res.code == 200) {
								this.$message.success('成功！')
								this.getProjectWorkData()
								this.$refs[formName].resetFields();
								this.workWindow = false
							}
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				});


			},
			editWorkWindow(item) {
				if (this.project.completion != 0 || item.stage != this.project.stage) return
				this.workForm = JSON.parse(JSON.stringify(item))
				this.workWindow = true
			},
			addWorkWindow() {
				this.workForm = {}
				this.workWindow = true
			},
			//初始化
			async init() {
				await this.getProjectInfo()
				await this.getDictData()
				this.getProjectWorkData()
			},
			//获取工作列表
			getProjectWorkData() {
				getProjectWorkList({
					projectId: this.$route.params.projectId
				}).then(res => {
					// console.log(res);
					// 插入check属性
					res.data.forEach(item => {
						item.list.map(work => {
							work.check = work.completion == 1
							return work
						})
					})
					this.workList = res.data
					this.workList.reverse()
				})
			},

			//获取项目信息
			async getProjectInfo() {
				await getProjectDetail(this.$route.params.projectId).then(res => {
					if (res.code == 200)
						this.project = res.data
				})
			},
			//获取字典数据
			async getDictData() {
				await getDict('wyy_project_stage').then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.stageList = res.data
						//排序
						this.stageList.sort((a, b) => b.dictSort - a.dictSort)
						//判断项目是否已完结
						if (this.stageList[0].dictValue == this.project.stage) {
							this.isEnd = true
						}
					}
				})
			}
		},
		created() {
			this.init()
		},
		mounted() {

		},
	}
</script>
<style>
	.back {
		z-index: 100;
		top: 40px !important;
	}

	.workForm .el-form-item__label,
	.el-textarea__inner {
		font-size: 20px !important;
	}

	.el-divider--horizontal {
		height: 2px !important;
		margin: 40px 0;
	}

	.el-divider__text {
		font-size: 40px;
		font-weight: bold;
	}

	.el-checkbox__inner {
		border: 1px solid #a1a3a7;
	}
</style>
<style lang="scss" scoped>
	i {
		font-size: 30px;
		color: #959595;
		cursor: pointer;
	}

	.container {
		width: 100vw;
		height: 100vh;
		padding: 30px 50px;
		// background-image: linear-gradient(#FFFDFB, #ffe5d1);
		background-color: #E8EFF9;

		h2 {
			font-size: 40px;
			text-align: center;
		}
	}

	.work-list-box {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -45%);
		width: 95%;
		height: 86%;
		background-color: #FFFFFF;
		box-shadow: 3px 3px 15px rgba(0, 0, 0, .3);
		border-radius: 8px;
		overflow: hidden;

		.menu {
			width: 100%;
			height: 50px;
			background-color: #f0f4ff;
			border-bottom: 1px solid #ccc;
			box-sizing: border-box;
			position: relative;

			.button-box {
				width: 200px;
				height: 100%;
				position: absolute;
				right: 0;
				display: flex;
				padding: 0 20px;
				justify-content: flex-end;
				align-items: center;
			}
		}

		.work-list {
			box-sizing: border-box;
			// 避免上边距重合
			padding-top: 5px;
			width: 100%;
			height: calc(100% - 50px);
			overflow: hidden;
		}

		.work-item {
			padding: 20px 50px;

			.work-title {
				font-size: 24px;
				display: flex;
				width: 100%;
				position: relative;

				.work-button {
					width: 200px;
					height: 100%;
					position: absolute;
					right: 30px;
					// background-color: pink;
					text-align: right;

					i {
						font-size: 26px;
						margin: 0 10px;
					}
				}
			}

		}

		.exec-type {
			position: absolute;
			width: 8px;
			height: 100%;
			left: 0;
			top: 0;
		}
	}
</style>
